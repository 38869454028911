import Vue from "vue";
import App from "./App.vue";
import router from "./router"; // Make sure this path is correct
import Vuetify from "vuetify"; // Import Vuetify
import "vuetify/dist/vuetify.min.css"; // Import Vuetify CSS

Vue.config.productionTip = false;

Vue.use(Vuetify); // Use Vuetify

new Vue({
  vuetify: new Vuetify(), // Add Vuetify instance here
  router, // Add the router here
  render: (h) => h(App),
}).$mount("#app");
