import Vue from "vue";
import Router from "vue-router";
import Personal from "./components/Personal.vue";
import Team from "./components/Team.vue";
import Home from "./components/Home.vue";

Vue.use(Router);

export default new Router({
  mode: "history", // Use history mode to remove the hash from URLs
  routes: [
    { path: "/personal", component: Personal },
    { path: "/team", component: Team },
    { path: "/", component: Home },
    // { path: "/", redirect: "/" }, // Default route
  ],
});
